import { createSlice, createSelector } from '@reduxjs/toolkit';
import centerOfMass from '@turf/center-of-mass';

const initialState = {
	tableLoading: false,
	manageLastRequestUrl: null,
	manageModal: {
		isOpen: false,
		selected: null
	},
	manageDrawer: {
		isOpen: false,
		drawerState: 'view',
		selectedID: null,
		isEditMode: false,
		data: {}
	},
	manageSortedTable: {
		list: [],
		activeTable: null,
		sortBy: null,
		sortDirection: true,
		initialLoad: true
	},
	managePagination: {
		current_page: 0,
		last_page: 0,
		limit: 0,
		offset: 0,
		total: 0
	},
	manageAddUser: {
		modal: {
			isOpen: false
		},
		payload: {}
	},
	selectedTableRows: [],
	showCheckboxes: false,
	taggableModelsCount: null,
	tableCSVDownloading: false,
	hoverMapItems: [],
	zoomToResultsBounds: false,
	queryUsersLoading: false,
	detailDrawerLoading: false,
	manageFilterMenuOpen: false,
	missionPlanningDrawerOpen: false,
	flightMergeLoading: null,
	loadedPages: []
};

const manageTablesSlice = createSlice({
	name: 'manageTables',
	initialState,
	reducers: {
		clearManageDrawerAndTable() {
			return initialState;
		},
		setTableLoading(state, action) {
			state.tableLoading = action.payload;
		},
		addToLoadedPages(state, action) {
			state.loadedPages = [...state.loadedPages, action.payload];
		},
		setManageLastRequestUrl(state, action) {
			state.manageLastRequestUrl = action.payload;
		},
		setManageModal(state, action) {
			state.manageModal = action.payload;
		},
		setManageDrawer(state, action) {
			return {
				...state,
				manageDrawer: { ...state.manageDrawer, ...action.payload }
			};
		},
		openDrawer(state, action) {
			return {
				...state,
				manageDrawer: {
					...state.manageDrawer,
					isOpen: true,
					drawerState: 'view',
					selectedID: action.payload
				}
			};
		},
		closeDrawer(state) {
			return {
				...state,
				manageDrawer: {
					...state.manageDrawer,
					isOpen: false,
					drawerState: null,
					selectedID: null,
					data: {}
				}
			};
		},
		viewDrawer(state) {
			return {
				...state,
				manageDrawer: {
					...state.manageDrawer,
					isOpen: true,
					drawerState: 'view'
				}
			};
		},
		setManageSortedTable(state, action) {
			return {
				...state,
				manageSortedTable: { ...state.manageSortedTable, ...action.payload }
			};
		},
		setManagePagination(state, action) {
			state.managePagination = action.payload;
		},
		setManageAddUser(state, action) {
			state.manageAddUser = action.payload;
		},
		setSelectedTableRows(state, action) {
			state.selectedTableRows = action.payload;
		},
		setShowCheckboxes(state, action) {
			state.showCheckboxes = action.payload;
		},
		setTaggableModelsCount(state, action) {
			state.taggableModelsCount = action.payload;
		},
		setTableCSVDownloading(state, action) {
			state.tableCSVDownloading = action.payload;
		},
		onLoadTableData(state, action) {
			const { paginate, data, activeTable, meta } = action.payload;
			return {
				...state,
				managePagination: paginate ? paginate : meta ? meta.page : state.managePagination,
				manageSortedTable: {
					...state.manageSortedTable,
					list: [...state.manageSortedTable.list, ...data],
					activeTable,
					meta,
					initialLoad: false
				}
			};
		},
		resetPagination(state) {
			return {
				...state,
				managePagination: initialState.managePagination,
				manageSortedTable: initialState.manageSortedTable,
				manageLastRequestUrl: initialState.manageLastRequestUrl,
				selectedTableRows: [],
				loadedPages: []
			};
		},
		setHoverMapItems(state, action) {
			state.hoverMapItems = action.payload;
		},
		setZoomToResultsBounds(state, action) {
			state.zoomToResultsBounds = action.payload;
		},
		setQueryUsersLoading(state, action) {
			state.queryUsersLoading = action.payload;
		},
		setDetailDrawerLoading(state, action) {
			state.detailDrawerLoading = action.payload;
		},
		setManageFilterMenuOpen(state, action) {
			state.manageFilterMenuOpen = action.payload;
		},
		setMissionPlanningDrawerOpen(state, action) {
			state.missionPlanningDrawerOpen = action.payload;
		},
		setFlightMergeLoading(state, action) {
			state.flightMergeLoading = action.payload;
		},
		resetManageTablesSlice() {
			return initialState;
		}
	}
});

export const {
	clearManageDrawerAndTable,
	setTableLoading,
	setManageLastRequestUrl,
	setManageModal,
	setManageDrawer,
	openDrawer,
	closeDrawer,
	viewDrawer,
	setManageSortedTable,
	setManagePagination,
	setManageAddUser,
	setSelectedTableRows,
	setShowCheckboxes,
	setTaggableModelsCount,
	setTableCSVDownloading,
	onLoadTableData,
	resetPagination,
	setAllUsersList,
	setHoverMapItems,
	setZoomToResultsBounds,
	setQueryUsersLoading,
	setDetailDrawerLoading,
	setManageFilterMenuOpen,
	setMissionPlanningDrawerOpen,
	resetManageTablesSlice,
	setFlightMergeLoading,
	addToLoadedPages
} = manageTablesSlice.actions;

export default manageTablesSlice.reducer;

export const manageLastRequestUrlSelector = state => state.manageTables.manageLastRequestUrl;
export const tableLoadingSelector = state => state.manageTables.tableLoading;
export const manageModalSelector = state => state.manageTables.manageModal;
export const manageDrawerSelector = state => state.manageTables.manageDrawer;
export const manageSortedTableSelector = state => state.manageTables.manageSortedTable;
export const managePaginationSelector = state => state.manageTables.managePagination;
export const manageAddUserSelector = state => state.manageTables.manageAddUser;
export const selectedTableRowsSelector = state => state.manageTables.selectedTableRows;
export const showCheckboxesSelector = state => state.manageTables.showCheckboxes;
export const taggableModelsCountSelector = state => state.manageTables.taggableModelsCount;
export const tableCSVDownloadingSelector = state => state.manageTables.tableCSVDownloading;
export const hoverMapItemsSelector = state => state.manageTables.hoverMapItems;
const listSelector = state => state.manageTables.manageSortedTable?.list;

export const featureCollectionSelector = createSelector([listSelector], list => {
	return list.flatMap(m => {
		let values = [];
		if (m.mission_area) {
			values.push({
				...m.mission_area,
				properties: {
					...m,
					...m.mission_area.properties,
					center: centerOfMass(m.mission_area)?.geometry?.coordinates,
					itemId: m.mission_id
				}
			});
		}
		values.push({
			...m.address,
			properties: {
				...m,
				...m.address.properties,
				center: m.address.geometry.coordinates,
				itemId: m.mission_id
			}
		});
		return values;
	});
});

export const zoomToResultsBoundsSelector = state => state.manageTables.zoomToResultsBounds;
export const resultsBoundsSelector = state => state.manageTables.manageSortedTable?.meta?.bounds;
export const queryUsersLoadingSelector = state => state.manageTables.queryUsersLoading;
export const detailDrawerLoadingSelector = state => state.manageTables.detailDrawerLoading;
export const manageFilterMenuOpenSelector = state => state.manageTables.manageFilterMenuOpen;
export const missionPlanningDrawerOpenSelector = state =>
	state.manageTables.missionPlanningDrawerOpen;
export const flightMergeLoadingSelector = state => state.manageTables.flightMergeLoading;
export const loadedPagesSelector = state => state.manageTables.loadedPages;
